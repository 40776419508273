
/*
    Custom script for thirdparty plugin: https://cookieconsent.popupsmart.com/gdpr-cookie-consent/
    This code is not compiled into the main file
*/

$(document).ready(function () {
    var $sr,
        $prefSr,
        i = 0,
        j = 0,
        styles = `
            <link rel="stylesheet" href="/assets/build/css/base.css">
            <link rel="stylesheet" href="/assets/build/css/main.min.css">
        `;

    var loopPrefSr = setInterval(function () {
        j++;
        $prefSr = $('#open_cookie_preferences > div'); // The custom link on the cookie-notice page to open the preference popup

        // Stop trying after 10 x 500 ms > popupsmart container not found on the DOM
        if ($prefSr.length || j == 10) {
            if ($prefSr.length && $prefSr[0].shadowRoot) {
                var head = $prefSr[0].shadowRoot.querySelector('head');
                head.innerHTML += styles;

                $('#open_cookie_preferences').on("click", function () {
                    $($prefSr[0].shadowRoot).find('.preferences__bottom button').addClass("btn c1-bg c5-text");
                });
            }

            clearInterval(loopPrefSr);
        }
    }, 500);

    var loopSr = setInterval(function () {
        i++;
        $sr = $('#popupsmart_cookie_consent_gdpr_root');

        // Stop trying after 10 x 500 ms > popupsmart container not found on the DOM
        if ($sr.length || i == 10) {
            if ($sr.length && $sr[0].shadowRoot) {
                var head = $sr[0].shadowRoot.querySelector('head');
                head.innerHTML += styles;

                $($sr[0].shadowRoot).find('button').removeClass('button');
                $($sr[0].shadowRoot).find('button:not(.prefButton').addClass("btn c1-bg c5-text");

                var $prefButton = $($sr[0].shadowRoot).find('button.prefButton');

                $prefButton.addClass("btn c5-bg c1-text");
                $prefButton.on("click", function () {
                    $($sr[0].shadowRoot).find('.preferences__bottom button').addClass("btn c1-bg c5-text");
                });
            }

            clearInterval(loopSr);
        }
    }, 500);
});